// default values for the panels
.page {
    .page-header {
        font-weight: bold;
    }

    .page-meta {
        font-weight: bold;
        padding-top: 0.5em;
        height: 2.5em;
        background-color: $page-meta-bg;
        border-bottom: 1px solid $page-meta-border;
    }

    .page-body {
        padding: 0;

        // if no meta information is to show, reset padding-top
        & > :not(.page-meta) {
            padding-top: 0.5em;
        }

        // scale larger (than the div) images to the size of the div
        img {
            max-width:100%;
            max-height:100%;
        }
    }

    .page-row {
        margin: 0;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        &:not(:last-child) {
            border-bottom: 1px solid $page-border;
        }
        &.hover:hover {
            background-color: $page-hover;
        }
    }

    .row > .page-row {
        &:not(:last-child) {
            border-bottom: 1px solid $page-border;
        }
    }
}

.row-unread {
    font-weight: bold;
}
