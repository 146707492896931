.btn-link {
    text-decoration: none;
}

.btn-icon {
    font-family: "Font Awesome 5 Free";
    font-size: 1em;
    line-height: 1.5em;
    background: none;
    border-radius: 0;
}

.btn-white {
    @include button-variant(
        $background: #fff,
        $border: #ccc,
        $hover-background: #e6e5e5,
        $hover-border: #adadad,
        $hover-color: #333
    );
}

.btn-danger {
    color: $white;
}

.btn-xs {
    $btn-xs-py: 1px;
    $btn-xs-px: 5px;
    $btn-xs-font-size: 11px;
    //$border-radius: 3px;

    @include button-size(
        $btn-xs-py,
        $btn-xs-px,
        $btn-xs-font-size,
        $border-radius
    );
}
